.btnAction-h {
    width: 100%;
    display: flex;
    justify-content: end;
}
.principal {
    display: flex;
    max-width: 67.5em;
    justify-content: center;
    align-items: flex-start;
    margin-inline: auto;
    padding-inline: 2.5rem;
    gap: 2rem;
    padding-top: 2rem;
}
.header{
  margin: 0 auto !important;
}
.dataGrid-header {
  color: #000000;
  background-color: #E8ECEF;
  font-size: 0.9rem;
}

.logoSprc {
  vertical-align: bottom;
}

.logoVigilado {
  vertical-align: bottom;
  padding-top: 0.3rem !important;
  text-align: left;
}

.logoContecar {
  vertical-align: bottom;
  text-align: right;
}
img{
  width: 9rem;
}
.titlePage{
  text-align: center !important;
  color: #0079ba !important;
  font-weight: bold;
  font-size: 1.5rem;
  padding-left: 1rem;
}