.center {
    width: 450px;
    min-height: 30rem;
    text-align: center;

}

.btnAction {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.login {

    display: flex;

    /* make main axis horizontal (default setting) */
    justify-content: center;
    /* center items horizontally, in this case */
    align-items: center;
    /* center items vertically, in this case */
    height: 100vh;

}

.header{
    margin: 0 auto !important;
}
.logoSprc {
vertical-align: bottom;
width: 20%;
}

.logoContecar {
width: 20%;
vertical-align: bottom;
text-align: right;
}
.titleLogin{
text-align: center !important;
color: #0079ba !important;
font-weight: bold;
font-size: 2rem;
padding-top: 1.5rem;
}